import React from "react";
import Seo from "../components/Seo/seo";

const NotFoundPage = () => (
  <div
    className="sections"
    style={{ padding: "24px", minHeight: "calc(100vh - 200px)" }}
  >
    <Seo title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </div>
);

export default NotFoundPage;
